@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~react-image-gallery/styles/css/image-gallery.css";


.modal_zoom_div{
  height: 100%; /* Fallback */
  height: -moz-available;
  height: -webkit-fill-available;
  /* height: fill-available; */
  height: stretch; /* Latest specification */
 
}

.product-big-img .slick-slide>div>div {
  display: flex !important;
  justify-content: center !important;
} 




@media (max-width: 840px){
  #popup_modal::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 130%;
    height: 100%;
    overflow-y: hidden;
    margin-left: -30px;
    margin-top: -15px;

    background-size: cover;
    filter: blur(30px);
    z-index: -1;
    
    }
}